




@font-face {
  font-family: 'imported-font-buttons';
  src: url('./Cinzel/static/Cinzel-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'imported-font-labels';
  src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
}



@font-face {
  font-family: 'imported-font-welcome';
  src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
}


@font-face {
  font-family: 'imported-font-result-text-name';
  src: url('./Cinzel/static/Cinzel-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'imported-font-result-text';
  src: url('./Lora/Lora-Italic-VariableFont_wght.ttf') format('truetype');
}






@font-face {
  font-family: 'imported-font';
  /* src: url('./Cinzel/static/Cinzel-Medium.ttf') format('tiff'); */
  /* src: url('./Crimson_Text/CrimsonText-SemiBoldItalic.ttf') format('tiff'); */

  /* src: url('./Parisienne/Parisienne-Regular.ttf') format('tiff'); */
  /* src: url('./Cinzel/Cinzel-VariableFont_wght.ttf') format('tiff'); */
    
  /* font-weight: normal;
  font-style: normal; */


  /* src: url('./GreatVibes-Regular.ttf') format('truetype'); */
  
  src: url('./Cinzel/static/Cinzel-Medium.ttf') format('truetype');

}

@font-face {
  font-family: 'imported-font-1';
  
  src: url('./Cinzel/static/Cinzel-Medium.ttf') format('truetype');

}


/* @font-face {
  font-family: 'imported-font-2';
  src: url('./GreatVibes-Regular.ttf') format('truetype');
} */


/* @font-face {
  font-family: 'imported-font-2';
  src: url('./Parisienne/Parisienne-Regular.ttf') format('truetype');
} */

@font-face {
  font-family: 'imported-font-2';
  src: url('./Homemade_Apple/HomemadeApple-Regular.ttf') format('truetype');
}

/* @font-face {
  font-family: 'imported-font-2';
  src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
} */



@font-face {
  font-family: 'imported-font-3';


  /* src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype'); */


  /* src: url('./GreatVibes-Regular.ttf') format('truetype'); */

  src: url('./Parisienne/Parisienne-Regular.ttf') format('truetype');




}




@font-face {
  font-family: 'imported-font-4';
  src: url('./Crimson_Text/CrimsonText-Italic.ttf') format('truetype');
}




@font-face {
  font-family: 'imported-font-5';
  src: url('./Lora/Lora-Italic-VariableFont_wght.ttf') format('truetype');
}



@font-face {
  font-family: 'imported-font-6';
  src: url('./Dancing_Script/DancingScript-VariableFont_wght.ttf') format('truetype');
}
