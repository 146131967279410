

/*
color palette

dark blue #034488
mid blue #178fd6
light blue #ccdde8
gray brown #edece8
off white #fffcf6

*/




.parentDiv {
    padding: 0px;
    margin: 0px;
    border: 0px;
    
    min-height: 100svh;
    background-color: #ccdde8;
    color: #034488;
    stop-color: '#74acdf';
}

.parentDiv2 {
    padding: 0px;
    margin: 0px;
    border: 0px;
    
    min-height: 100svh;
    background-color: #ccdde8;
    /* // color: #609cd4; */
    color: #8dbbe6;
}
